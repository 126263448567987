import React, { useEffect } from 'react'
import Head from 'next/head'
import type { GetStaticProps, InferGetStaticPropsType, NextPage } from 'next'
import { useRouter } from 'next/router'

import useBodyClass from 'hooks/bodyClass'
import useAnalytics from 'hooks/useAnalytics'

import ResponsivePageLayout from 'components/Layout/ResponsivePageLayout'
import Gtm from 'components/scripts/Gtm'
import SearchBar from 'components/Search/SearchBar'
import RecentSearches from 'components/Search/RecentSearches/NotFoundPageSearches'
import CustomLink from 'components/Link/CustomLink'
import RecommendedProperties from 'components/RecommendedProperties/RecommendedProperties'
import RecommendedDestinationsCarousel from 'components/Carousels/RecommendedDestinationsCarousel'

import style from './404.module.scss'

import { HOME_PAGE_IDENTIFIER } from 'config/cms'

import { fetchFooterData, fetchGlobalOptionsData } from 'utils/staticData'

import type { InitialSearch } from 'types/externalData'

const title = '404 - Evolve Vacation Rental'

const helpfulLinks = [
  { href: '/', title: 'Home' },
  {
    href: '/owner',
    title: 'Become an Owner',
  },
  { href: '/contact', title: 'Help' },
  { href: '/blog', title: `Tips & Articles` },
]

export const getStaticProps: GetStaticProps = async () => {
  const data = await (
    await fetch(
      `${process.env.NEXT_PUBLIC_BASE_WP_API_URL}/wp-json/wp/v2/pages/?page_identifier=${HOME_PAGE_IDENTIFIER}`,
    )
  ).json()
  const footerData = await fetchFooterData()
  const globalOptionsData = await fetchGlobalOptionsData()
  const pageFooterData = {
    menus: footerData,
    options: globalOptionsData.acf.global_footer_group,
  }

  return {
    props: {
      propertyData: data[0]['acf']['recommended_property'],
      footerData: pageFooterData,
      recommendedDestinations:
        globalOptionsData.acf.global_recommended_destinations,
    }, // will be passed to the page component as props
  }
}

const ErrorPage: NextPage<InferGetStaticPropsType<typeof getStaticProps>> = ({
  propertyData,
  footerData,
  recommendedDestinations,
}) => {
  useBodyClass('404')
  const analytics = useAnalytics()
  const router = useRouter()

  useEffect(() => {
    /* TODO: When is hard navigation window.analytics
      is not defined yet, that is why this trigger
      has to be moved at the end of the event loop.
    */
    setTimeout(() => {
      /* Track users visitting 404 pages PageHistoryContext
        does not work  when hard navigating 
      */
      const previousPage = document.referrer || null
      const url = window.location.href
      const path = router.asPath.split('?')[0]

      analytics.viewNotFoundPage({
        path,
        previousPage,
        title,
        url,
      })
    }, 0)
  }, [analytics, router.asPath])

  const headerData = {
    hideSearchBox: true,
  }

  return (
    <ResponsivePageLayout footerData={footerData} headerData={headerData}>
      <Head>
        <title>{title}</title>
      </Head>
      <Gtm pageType="404 page" />
      <section>
        <div className={style.mainContent}>
          <h5>ERROR CODE: 404</h5>
          <h1>Oops!</h1>
          <p>
            Seems this page is on vacation.
            <br />
            Search for a vacation rental you can count on.
          </p>
          <SearchBar
            initialSearch={{ address: { address: '' } } as InitialSearch}
            searchBarClass={style.notFoundSearch}
          />
        </div>
        <div className={style.linkSection}>
          <div className={style.recentActivity}>
            <RecentSearches />
          </div>
          <div className={style.linkList}>
            <h5 className={style.linkListTitle}>Helpful Links</h5>
            <ul>
              {helpfulLinks.map((link) => (
                <li key={link.title}>
                  <CustomLink
                    href={link.href}
                    rel="noopener noreferrer"
                    title={link.title}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
      {propertyData && (
        <RecommendedProperties
          gradient
          recommendedData={{
            ...propertyData,
            featured_properties: propertyData.featured_property,
          }}
          styleClass="responsiveLayout"
        />
      )}
      <RecommendedDestinationsCarousel
        id="not_found_recommended_destinations"
        recommendedDestinations={recommendedDestinations}
        styleClass="responsiveLayout"
      />
    </ResponsivePageLayout>
  )
}

export default ErrorPage
