import React from 'react'
import dayjs from 'dayjs'
import type { SearchState } from 'react-instantsearch-core'

import style from './NotFoundPageSearches.module.scss'

import { decodeSearchQuery } from 'utils/Strings'

type GroupedSearch = {
  [index: string]: SearchState[]
}

const searches = []

const filterSearches = () => {
  let filteredSearches: SearchState[] = []
  if (searches.length) {
    // Group multiple searches with same base query
    const groupedSearches: GroupedSearch = searches.reduce(
      (searchGroups: GroupedSearch, search: SearchState) => ({
        ...searchGroups,
        [search.state.query]: [
          ...(searchGroups[search.state.query] || []),
          search,
        ],
      }),
      {},
    )

    Object.keys(groupedSearches).forEach((key) => {
      const group = groupedSearches[key]
      // Always show unique searches
      if (group.length === 1) {
        filteredSearches = [...filteredSearches, group[0]]
      } else {
        // Always show search from group with the most parameters
        const preferredSearch = group.reduce(
          (prev: SearchState, curr: SearchState) => {
            return Object.keys(prev.state).length >
              Object.keys(curr.state).length
              ? prev
              : curr
          },
        )
        filteredSearches = [...filteredSearches, preferredSearch]
        // Only show search with same base query as preferredSearch if dates or adults are different,
        // and there is no search showing with the same number of adults
        const adultCount: number[] = []
        group.forEach((search: SearchState) => {
          const sameDates =
            search.state.startDate === preferredSearch.state.startDate &&
            search.state.endDate === preferredSearch.state.endDate
          const adultCountAdded = adultCount.includes(search.state.adults)
          if (
            (!sameDates && !adultCountAdded) ||
            (sameDates &&
              search.state.adults !== preferredSearch.state.adults &&
              !adultCountAdded)
          ) {
            adultCount.push(search.state.adults)
            filteredSearches = [...filteredSearches, search]
          }
        })
      }
    })
  }
  return filteredSearches
}

const RecentSearches = () => {
  // Filter searches to remove duplicates
  const filteredSearches = filterSearches()

  return filteredSearches.length ? (
    <div className={style.listContainer}>
      <h5 className={style.listTitle}>Recent Searches</h5>
      <div className={style.recentList}>
        {filteredSearches.slice(0, 4).map((search, i) => {
          return (
            <div className={style.recentItem} key={i}>
              <a
                className={style.searchLink}
                href={search.pathname + search.search}
              >
                <p className={style.query}>
                  {decodeSearchQuery(search.state.query)}
                </p>
                {search.state.startDate && search.state.endDate && (
                  <p className={style.dates}>{`${dayjs(
                    search.state.startDate,
                  ).format('MMM DD')} - ${dayjs(search.state.endDate).format(
                    'MMM DD',
                  )}`}</p>
                )}
                {search.totalGuests >= 1 && (
                  <p className={style.guests}>{`${search.totalGuests} ${
                    search.totalGuests > 1 ? 'Guests' : 'Guest'
                  }`}</p>
                )}
              </a>
            </div>
          )
        })}
      </div>
    </div>
  ) : null
}

export default RecentSearches
