// Notes/readme for using page layouts can be found here https://evolvevrn.atlassian.net/wiki/spaces/SITE/pages/2283405532/Page+Layout+Readme+notes
import React, { useCallback } from 'react'

import { useDispatch } from 'store/index'
import { setQuery } from 'store/search'

import { setDetailPageUrl } from 'reducers/searchData'

import useAugmentedRouter from 'hooks/useAugmentedRouter'

import type { HeaderProps } from 'components/Header/Header'
import Header from 'components/Header/Header'
import Footer from 'components/Footer/Footer'
import SearchBox from 'components/Search/SearchBox'

import style from './ResponsivePageLayout.module.scss'

import { searchStateToUrl } from 'utils/Search'

type ResponsivePageLayoutProps = {
  headerData?: HeaderProps & { hideSearchBox?: boolean }
  footerData: any
  children: React.ReactNode
}

const ResponsivePageLayout: React.FC<ResponsivePageLayoutProps> = ({
  headerData,
  footerData,
  children,
}) => {
  const router = useAugmentedRouter()

  // Redux actions
  const appDispatch = useDispatch()

  const handleQuerySelect = useCallback(
    (query) => {
      const searchState = {
        query: query,
      }
      const searchRoute = searchStateToUrl(searchState)
      appDispatch(setQuery(query))
      appDispatch(setDetailPageUrl(router.pathname))
      router.push('/vacation-rentals/search' + searchRoute)
    },
    [appDispatch, router],
  )

  return (
    <>
      <Header
        fullNavWidth={headerData?.fullNavWidth}
        navHidden={headerData?.navHidden}
        needHelpLink={headerData?.needHelpLink}
        page={headerData?.page}
        step={headerData?.step}
        transparent={headerData?.transparent}
      >
        {!headerData?.hideSearchBox && (
          <SearchBox onQuerySelect={handleQuerySelect} />
        )}
      </Header>
      <section className={style.responsivePage}>
        <section className={style.responsivePageLayout}>{children}</section>
        <Footer footerData={footerData} />
      </section>
    </>
  )
}

export default ResponsivePageLayout
