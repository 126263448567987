import React from 'react'

//Style
import style from './RecommendedProperties.module.scss'

// Components
import PropertyCarousel from '../Carousels/Property-Carousel'
import CustomLink from '../Link/CustomLink'

type RecommendedPropertiesProps = {
  recommendedData: any
  gradient?: boolean
  styleClass?: string
}

const RecommendedProperties: React.FC<RecommendedPropertiesProps> = ({
  recommendedData,
  gradient,
  styleClass,
}) => {
  const { label, title, subtitle, button, featured_properties } =
    recommendedData

  return (
    <section
      className={`${style.section} ${gradient ? style.hasGradient : ''}
      ${styleClass ? style[styleClass] : ''}`}
      id="properties"
    >
      <div className={`${style.content} content`}>
        <div className={`${style.recommendedHeader}`}>
          <div className={`${style.recommendedLabel} carousel-title`}>
            <span className={'section-label blue'}>{label}</span>
            <h2 className={`${style.recommendedTitle} section-title-sm`}>
              {title}
            </h2>
            <span
              className={`${style.recommendedSubtitle} section-subtitle-sm`}
            >
              {subtitle}
            </span>
          </div>
          {button && (
            <CustomLink
              className={`${style.recommendedButton} btn-secondary`}
              href={button.link}
            >
              {button.text}
            </CustomLink>
          )}
        </div>
        <PropertyCarousel data={featured_properties} styleClass={styleClass} />
      </div>
    </section>
  )
}

export default RecommendedProperties
